// ScoringContext.js
import React, { createContext, useState } from 'react';

export const ScoringContext = createContext();

export const ScoringProvider = ({ children }) => {
  const [scored, setScored] = useState(null);

  return (
    <ScoringContext.Provider value={{ scored, setScored }}>
      {children}
    </ScoringContext.Provider>
  );
};