export const isEnglishText = (text) => {
    const englishTextRegex = /^[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~\s]*$/;
    return englishTextRegex.test(text);
  };
  export const isValidYouTubeURL = (url) => {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be|drive\.google\.com|vimeo\.com)\/[a-zA-Z0-9_-]+(\/[a-zA-Z0-9_-]+)*(\?[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+(&[a-zA-Z0-9_-]+=[a-zA-Z0-9_-]+)*)?$/i;
    return youtubeRegex.test(url);
};

  export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };