import React, { useState, useEffect } from 'react';
import '../styles/submissionFormStyle.scss';
import axios from 'axios';
import logo from '../images/logo.png';
import BN from '../images/flags/bn.svg';
import ID from '../images/flags/id.svg';
import KH from '../images/flags/kh.svg';
import LA from '../images/flags/la.svg';
import MM from '../images/flags/mm.svg';
import MY from '../images/flags/my.svg';
import PH from '../images/flags/ph.svg';
import SG from '../images/flags/sg.svg';
import TH from '../images/flags/th.svg';
import VN from '../images/flags/vn.svg';
import tooltipIcon from '../icons/tooltip.svg'
import binIcon from '../icons/bin.svg'
import { Link } from 'react-router-dom';
import useAuth from '../utils/authentication';
import Footer from '../components/footer';
import ImageUploader from '../components/uploadImage';
import FileUploader from '../components/uploadFile';
import SubmitBtn from '../components/modal/submitFormButton'
import CancelBtn from '../components/modal/cancelFormButton'
import LogoutModal from '../components/modal/logoutModal';
import { isEnglishText, isValidYouTubeURL, isValidEmail } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Tooltip, Button } from 'antd'
function SubmissionForm() {
  document.title = "Submission form | Judging.AseanDigitalAwards2025"
  const navigate = useNavigate();
  useAuth();
    const userData = JSON.parse(localStorage.getItem('user'))
    const userCountry = userData.country
    const token = localStorage.getItem('token')
    const countryFlags = {
      BN: BN,
      ID: ID,
      KH: KH,
      LA: LA,
      MM: MM,
      MY: MY,
      PH: PH,
      SG: SG,
      TH: TH,
      VN: VN
    }
    const countryNames = {
      BN: 'Brunei',
      ID: 'Indonesia',
      KH: 'Cambodia',
      LA: 'Laos',
      MM: 'Myanmar',
      MY: 'Malaysia',
      PH: 'Philippines',
      SG: 'Singapore',
      TH: 'Thailand',
      VN: 'Vietnam',
    };
    const [categories, setCategories] = useState([
      { value: 1, label: 'Public Sector' },
      { value: 2, label: 'Private Sector' },
      { value: 3, label: 'Digital Inclusivity' },
      { value: 4, label: 'Digital Content' },
      { value: 5, label: 'Digital Start-up' },
      { value: 6, label: 'Digital Innovation' }
    ]);
    useEffect(() => {
      const fetchCategoryCount = async () => {
        try {
          const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/category-count', {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          const categoryCounts = response.data;
          setCategories(prevCategories => {
            return prevCategories.map(category => {
              const categoryCount = categoryCounts.find(count => count.category === category.value);
              if (categoryCount && (categoryCount.count === 3 || categoryCount.is_full)) {
                return { ...category, label: `${category.label} [Full]`, disabled: true };
              }
              return category;
            });
          });
        } catch (error) {
          console.error('Error fetching category count:', error);
        }
      };
      fetchCategoryCount();
    }, [token]);
    const { TextArea } = Input;
    const countryInput = countryNames[userCountry];
    const [category, setCategory] = useState(Number);
    const [teamName, setTeamName] = useState('');
    const [teamMembers, setTeamMembers] = useState(['', '']);
    const [contactEmail, setContactEmail] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectDesc, setProjectDesc] = useState('');
    const [projectGoal, setProjectGoal] = useState('');
    const [projectProblem, setProjectProblem] = useState('');
    const [projectKey, setProjectKey] = useState('');
    const [projectInnovation, setProjectInnovation] = useState('');
    const [projectURL, setProjectURL] = useState('');
    const [thumbnailFile, setThumbnailFile] = useState(null)
    const [infographicFile, setInfographicFile] = useState("");
    const [pitchDeckFile, setPitchDeckFile] = useState(null);
    const [additionalFiles, setAdditionalFiles] = useState([]);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const [isInvalidFileType, setIsInvalidFileType] = useState(false);
    const [isFileTooLarge, setIsFileTooLarge] = useState(false);
    const [errorGraphicMessage, setErrorGraphicMessage] = useState(false);
    const [errorPitchDeckMessage, setErrorPitchDeckMessage] = useState(false);
    const [errorAdditionalMessage, setErrorAdditionalMessage] = useState(false);
    const [errorTeamName, setErrorTeamName] = useState(false);
    const [errorTeamMembers, setErrorTeamMembers] = useState([false]);
    const [errorContactEmail, setErrorContactEmail] = useState(false);
    const [errorProjectTitle, setErrorProjectTitle] = useState(false);
    const [errorProjectDesc, setErrorProjectDesc] = useState(false);
    const [errorProjectGoal, setErrorProjectGoal] = useState(false);
    const [errorProjectProblem, setErrorProjectProblem] = useState(false);
    const [errorProjectKey, setErrorProjectKey] = useState(false);
    const [errorProjectInnovation, setErrorProjectInnovation] = useState(false);
    const [errorProjectURL, setErrorProjectURL] = useState(false);
    const [categoryTouched, setCategoryTouched] = useState(false);
    const [teamNameTouched, setTeamNameTouched] = useState(false);
    const [teamMemberTouched, setTeamMemberTouched] = useState([false]);
    const [contactEmailTouched, setContactEmailTouched] = useState(false);
    const [projectTitleTouched, setProjectTitleTouched] = useState(false);
    const [projectDescTouched, setProjectDescTouched] = useState(false);
    const [projectURLTouched, setProjectURLTouched] = useState(false)
    const handleCategoryChange = (value) => {
      setCategory(value);
      setCategoryTouched(true);
    };
    const handleInputChange = (setter, errorSetter, validator, optional = false) => (e) => {
    const value = e.target.value;
    setter(value);
    if (!value && !optional) {
      errorSetter(true);
    } else if (validator ? !validator(value) : !isEnglishText(value)) {
      errorSetter(true);
    } else {
      errorSetter(false);
    }
  };
  const handleTeamNameChange = (e) => {
    handleInputChange(setTeamName, setErrorTeamName, isEnglishText)(e)
    setTeamNameTouched(true)
  }
  const handleProjectTitleChange = (e) => {
    handleInputChange(setProjectTitle, setErrorProjectTitle, isEnglishText)(e)
    setProjectTitleTouched(true)
  }
  const handleProjectDescChange = (e) => {
    handleInputChange(setProjectDesc, setErrorProjectDesc, isEnglishText)(e)
    setProjectDescTouched(true)
  }
  const handleProjectURLChange = (e) => {
    handleInputChange(setProjectURL, setErrorProjectURL, isValidYouTubeURL)(e)
    setProjectURLTouched(true)
  }
  const handleContactEmailChange = (e) => {
    handleInputChange(setContactEmail, setErrorContactEmail, isValidEmail)(e)
    setContactEmailTouched(true)
  }
  // const handleEmailChange = (setter, errorSetter, optional = true) => (e) => {
  //   setContactEmailTouched(true);
  //   const value = e.target.value;
  //   setter(value);
  //   if (value && !isValidEmail(value)) {
  //     errorSetter(true);
  //   } else {
  //     errorSetter(false);
  //   }
  // };

  const handleFileTooLarge = (isTooLarge) => {
    setIsFileTooLarge(isTooLarge);
  };
  const handleInvalidFileType = (isInvalid) => {
    setIsInvalidFileType(isInvalid);
  };
  const handleAddMember = () => {
    if (teamMembers.length < 10) {
      setTeamMembers([...teamMembers, '']);
    }
  };

  const handleRemoveMember = (index) => {
    if (index <= 1) {
      return;
    }
    const newTeamMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newTeamMembers);
    const newErrorTeamMembers = errorTeamMembers.filter((_, i) => i !== index);
    setErrorTeamMembers(newErrorTeamMembers);
    const newTeamMembersTouched = teamMemberTouched.filter((_, i) => i !== index);
    setTeamMemberTouched(newTeamMembersTouched);
  };

  const handleMemberChange = (index, value) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index] = value;
    setTeamMembers(newTeamMembers);
    const newErrorTeamMembers = [...errorTeamMembers];
    newErrorTeamMembers[index] = !isEnglishText(value);
    setErrorTeamMembers(newErrorTeamMembers);
    const newTeamMembersTouched = [...teamMemberTouched];
    newTeamMembersTouched[index] = true;
    setTeamMemberTouched(newTeamMembersTouched);
  };
  const handleUploadThumbnail = async (file) => {
    //console.log('thumb file: ', file);
    
    const formData = new FormData();
    formData.append('type', 'thumbnail')
    formData.append('file', file);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      const thumbnailPath = response.data.path
      setThumbnailFile(thumbnailPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  }
  const handleUploadGraphic = async (file) => {
    const graphicFile = file.file.originFileObj;
    const formData = new FormData();
    formData.append('type', 'infographic')
    formData.append('file', graphicFile);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      const infoGraphicPath = response.data.path
      setInfographicFile(infoGraphicPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleUploadPitchDeck = async (file) => {
    // console.log('Pitch deck file: ', file);
    const pitchDeckFile = file.file
    const formData = new FormData();
    formData.append('type', 'pitch_deck')
    formData.append('file', pitchDeckFile);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      const pitchDeckPath = response.data.path
      // console.log(pitchDeckPath);
      
      setPitchDeckFile(pitchDeckPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleUploadAdditional = async (file) => {
    // console.log('Additional file: ', file);
    const additionalFile = file.file.originFileObj
    const formData = new FormData();
    formData.append('type', 'additional')
    formData.append('file', additionalFile)
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      //console.log('response:', response.data);
      const additionalPath = response.data.path
      //console.log(additionalPath);
      setAdditionalFiles((prevFiles) => [...prevFiles, additionalPath]);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleRemoveAdditional = (file) => {
    //console.log(file);
    setAdditionalFiles((prevFiles) => prevFiles.filter((f) => f.name !== file.name));
  };
  const handleRemoveGraphic = (file) => {
    //console.log('remove graphic: ', file);
    setInfographicFile('');
  }
  const handleRemovePitchDeck = (file) => {
    //console.log('remove pitchDeck: ', file);
    setPitchDeckFile(null);
  }
  const uniqueFilesMap = new Map();
  const handleSubmit = async (e) => {
    // e.preventDefault()
    //console.log('additional File add: ', additionalFiles);
    additionalFiles.forEach(file => {
      const fileName = file.split('/').pop();
      if (!uniqueFilesMap.has(fileName)) {
        uniqueFilesMap.set(fileName, file);
      }
    });
    const uniqueFiles = Array.from(uniqueFilesMap.values()).map(file => ({
      path: file
    }));
    setAdditionalFiles(uniqueFiles);
    //console.log('uniqueFiles: ', uniqueFiles);
    const formData = {
      category,
      team_name: teamName,
      team_members: teamMembers.map((member)=>({name: member})),
      contact_email: contactEmail,
      title: projectTitle,
      description: projectDesc,
      propose_and_goals: projectGoal,
      problem_and_solution: projectProblem,
      key_features: projectKey,
      innovation: projectInnovation,
      video_url: projectURL,
      thumbnail: thumbnailFile,
      infographic: infographicFile,
      pitch_deck: pitchDeckFile,
      additional_files: uniqueFiles
    }
    //console.log(formData);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      //console.log('Response: ', response.data);
      // setAdditionalFiles([]);
      navigate('/create-success', { state: { team_id: response.data.team_id } });
    } catch (error) {
      // setAdditionalFiles([]);
      console.error('Error: ', error);
    }
  }
  const handleCancel = (e) => {
    navigate('/overview')
  }
  const handleLogoutModalOpen = () => {
    setIsLogoutModalOpen(true);
  }
  const handleLogoutModalClose = () => {
  setIsLogoutModalOpen(false);
  }
  const handleLogoutConfirm = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  window.location.href = '/';
  }
  const isSubmitDisabled = 
    isFileTooLarge || 
    errorGraphicMessage || 
    errorPitchDeckMessage || 
    errorAdditionalMessage || 
    errorTeamName || 
    errorTeamMembers.includes(true) || 
    errorContactEmail || 
    errorProjectTitle || 
    errorProjectDesc || 
    errorProjectGoal || 
    errorProjectProblem || 
    errorProjectKey || 
    errorProjectInnovation || 
    errorProjectURL ||
    !category || 
    !teamName || 
    teamMembers.some(member => !member) || 
    !contactEmail ||
    !projectTitle || 
    !projectDesc;
  return (
    <>
    <div className="projectsubmit">
        <div className="content">
        <section className="top">
      <div className="top-logo">
        <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
      </div>
      <div className="top-breadcrumb">
        <span onClick={handleLogoutModalOpen} className="bread-home">Home</span> 
        <span> / </span>
        <CancelBtn class="overview" breadcrumb={true} onCancel={handleCancel}/>
        <span> / </span>
        <Link to="/overview/submission-form" className="bread-submissionform">Submission Form</Link>
      </div>
      <div className="top-title">
        <span className="H1">Submit Project for ASEAN Digital Awards 2025</span>
        <img src={countryFlags[userCountry]} width={32} height={32} alt={`${userCountry} flag`} />
      </div>
      <div className="top-desc">
        <p className="Body">
        Kindly complete information below.
        </p>
      </div>
    </section>
    <section className="form-section">
      <form className="project-form">
      <div className="identity-infomation">
        <h1 className="Title-1">1. Identity Information <span className="text-red">(Required)</span></h1>
        <div className="info-identity">
          <div className="identity-field">
            <label htmlFor="country" className="Subtitle">Country :</label>
            <Input value={countryInput} id="country" placeholder="country" readOnly/>
            <Tooltip placement="topRight" title={countryInput}>
              <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
            </Tooltip>
          </div>
          <div className="identity-field">
            <label htmlFor="category" className="Subtitle">Select Category* :</label>
            <div className="input-container">
              <Select id="category" placeholder="Choose the  relevant category" className={!category && categoryTouched ? 'error' : ''}
              onChange={handleCategoryChange} 
              options={categories}
              />
              {!category && categoryTouched && <span className="error-text">Input required.</span>}
            </div>
            <Tooltip placement="topRight" title="Choose your category that best represents your 
                                                 project’s sector for depa ASEAN Digital Awards.">
              <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
            </Tooltip>
          </div>
          <div className="identity-field">
            <label htmlFor="teamName" className="Subtitle">Team Name* :</label>
            <div className="Body input-container">
            <Input required id="teamName" placeholder="Enter the team name" 
                   className={(!teamName && teamNameTouched) || errorTeamName ? 'errorInput' : ''}
                   onChange={handleTeamNameChange}
                   maxLength={280}
            />
            {(!teamName && teamNameTouched && <span className="error-text">Input required.</span>) || 
            (errorTeamName && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
            </div>
            <Tooltip placement="topRight" title="Enter your official name of your team or 
                                                 organization participating in this competition.">
              <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
            </Tooltip>
          </div>
          <div className="identity-field-member">
            <label htmlFor="teamMembers" className="Subtitle">Team Members* :</label>
            <div className="team-member-field">
              {teamMembers.map((member, index) => (
                <div key={index} className="team-member-input input-container">
                  <Input required value={member} onChange={(e) => handleMemberChange(index, e.target.value)}
                         placeholder="Enter first name and last name of team member"
                         className={(!member && teamMemberTouched[index]) || errorTeamMembers[index] ? 'errorInput' : ''}
                         suffix={<img src={binIcon} alt="Remove" width={16} height={16} maxLength={280}
                         onClick={() => handleRemoveMember(index)} style={{ cursor: 'pointer' }}
                          />
                        }
                      />
                      {(!member && teamMemberTouched[index] && <span className="error-text">Input required.</span>) ||
                      (errorTeamMembers[index] && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                </div>
              ))}
            </div>
                  <Tooltip className="tooltips-member" placement="topRight" title="Click ‘Add’ your team member's first and last name into the filling blank
                                                       by entering without a courtesy title such as ‘Mr.’ ‘Mrs.’ or ‘Miss’.">
                    <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                  </Tooltip>
          </div>
          <div className="add-btn">
          <label className="spacer"></label>
            {teamMembers.length < 10 && (
              <Button onClick={handleAddMember} type="default">+ Add</Button>
            )}
            </div>
            <div className="identity-field">
            <label htmlFor="contactEmail" className="Subtitle">Contact Email* :</label>
            <div className="input-container">
                <Input id="contactEmail" placeholder="Enter your email address" 
                       className={errorContactEmail ? 'errorInput' : ''} maxLength={280}
                       onChange={handleContactEmailChange}
                />
              {(!contactEmail && contactEmailTouched && <span className="error-text">Input required.</span>) || 
            (errorContactEmail && <span className="error-text">Please enter a valid email address.</span>)}
            </div>
            <Tooltip placement="topRight" title="Provide a contact email address for any
                                                 correspondence related to your submission.">
              <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
            </Tooltip>
          </div>
         </div>
        </div>
        <div className="project-infomation">
        <h1 className="Title-1">2. Team Infomation <span className="text-gray">(This information part labelled * is required to explain project detail of your country.)</span></h1>
            <div className="info-project">
              <div className="project-field">
                <label htmlFor="projectTitle" className="Subtitle">Project Title* :<br /> <span className="Body text-red">(Required)</span></label>
                <div className="input-container">
                  <Input required id="projectTitle" placeholder="Enter the official title of your project" 
                         className={(!projectTitle && projectTitleTouched) || errorProjectTitle ? 'errorInput' : ''}
                         onChange={handleProjectTitleChange}
                  />
                  {(!projectTitle && projectTitleTouched && <span className="error-text">Input required.</span>) ||
                  (errorProjectTitle && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                </div>
                <Tooltip placement="topRight" title="Enter a clear and concise title for your project. 
                                                     This will be displayed during the evaluation process.">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
              <div className="project-area-field-desc">
                <label htmlFor="projectDesc" className="Subtitle">Project Description* :<br /> <span className="Body text-red">(Required)</span></label>
                <div className={(!projectDesc && projectDescTouched) || errorProjectDesc? 'input-container input-container-error' : "input-container"}>
                  <TextArea required className="desc-area" 
                  id={(!projectDesc && projectDescTouched) || errorProjectDesc ? 'errorInput' : 'projectDesc'} 
                  placeholder="Provide a brief overview of your project" 
                  showCount
                  maxLength={1000}
                  onChange={handleProjectDescChange}
                  />
                  {(!projectDesc && projectDescTouched && <span className="error-text">Input required.</span>) ||
                  (errorProjectDesc && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                </div>
                <Tooltip placement="topRight" title="Provide notably overview of your project. Focus on ‘what’ 
                                                     and ‘why’ of the project, and avoid not required detail.">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
              <div className="project-area-field-url">
                  <label htmlFor="uploadVideo" className="Subtitle">Project Video URL* :<br /> <span className="Body text-red">(Required)</span></label>
                  <div className="input-container">
                    <Input id="uploadVideo" placeholder="Enter the link to your project video"
                         className={errorProjectURL ? 'errorInput' : ''} maxLength={280}
                         onChange={handleProjectURLChange}
                    />
                    <span className="text-gray mt-[8px] pl-[4px]">The video link you can insert can be Youtube link, Google  link, or any video url.</span>
                    {!projectURL && projectURLTouched && <span className="error-text">Input required.</span>}
                    {errorProjectURL && projectURL &&<span className="error-text">Invalid URL format. Please enter a valid YouTube URL, Google link, or any video url.</span>}
                  </div>
                  <Tooltip placement="topRight" title="Provide a YouTube link or streaming video link to showcase your project. 
                                                       Ensure that link is accessible and set to public.">
                    <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                  </Tooltip>
                </div>
              <div className="project-area-field">
                <label htmlFor="projectGoal" className="Subtitle text-gray">Purpose and Goals :<br /> <span className="Body text-gray">(optional)</span></label>
                <div className={errorProjectGoal? 'input-container input-container-error' : "input-container"}>
                  <TextArea 
                  required 
                  id={errorProjectGoal ? 'errorInput' : 'projectGoals'}
                  placeholder="(Optional) Provide a brief overview of your project" 
                  showCount 
                  maxLength={1000}
                  onChange={handleInputChange(setProjectGoal, setErrorProjectGoal, undefined, true)}
                  />
                  {errorProjectGoal &&<span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                </div>
                <Tooltip placement="topRight" title="Describe project's purpose and objectives . 
                                                     What does it aim to achieve, and why is it important?">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
              <div className="project-area-field">
                <label htmlFor="projectProblem" className="Subtitle text-gray">Problem and Solution :<br /> <span className="Body text-gray">(optional)</span></label>
                <div className={errorProjectProblem? 'input-container input-container-error' : "input-container"}>
                  <TextArea  
                  id={errorProjectProblem ? 'errorInput' : 'projectProblem'} 
                  placeholder="(Optional) Provide a brief overview of your project" 
                  showCount 
                  maxLength={1000}
                  onChange={handleInputChange(setProjectProblem, setErrorProjectProblem, undefined, true)}
                  />
                  {errorProjectProblem &&<span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                </div>
                <Tooltip placement="topRight" title="Outline the specific problem your project addresses and provide 
                                                     offered solution. Highlight project's unique approach and benefits.">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
              <div className="project-area-field">
                <label htmlFor="projectKey" className="Subtitle text-gray">Key Features and Impact :<br /> <span className="Body text-gray">(optional)</span></label>
                <div className={errorProjectKey? 'input-container input-container-error' : "input-container"}>
                  <TextArea 
                  id={errorProjectKey ? 'errorInput' : 'projectKey'} 
                  placeholder="(Optional) Provide a brief overview of your project" 
                  showCount 
                  maxLength={1000}
                  onChange={handleInputChange(setProjectKey, setErrorProjectKey, undefined, true)}
                  />
                  {errorProjectKey &&<span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                </div>
                <Tooltip placement="topRight" title="Explain dominant key features and its potential impact. 
                                                     Focus on what sets it apart from others.">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
              <div className="project-area-field">
                <label htmlFor="projectInnovation" className="Subtitle text-gray">Innovation and Methodology :<br /> <span className="Body text-gray">(optional)</span></label>
                <div className={errorProjectInnovation? 'input-container input-container-error' : "input-container"}>
                <TextArea 
                  id={errorProjectInnovation? 'errorInput' : 'projectInnovation'} 
                  placeholder="(Optional) Provide a brief overview of your project" 
                  showCount 
                  maxLength={1000}
                  onChange={handleInputChange(setProjectInnovation, setErrorProjectInnovation, undefined, true)}
                  />
                  {errorProjectInnovation &&<span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                </div>
                <Tooltip placement="topRight" title="Describe any innovative approaches or methodologies used in your project.
                                                     This field is optional but encouraged for more context.">
                  <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                </Tooltip>
              </div>
            </div>
        </div>
        <div className="upload-infomation">
          <h1 className="Title-1">3. Upload file <span className="Title-1 text-gray">(This is an optional part for uploading project files)</span></h1>
            <div className="info-upload">
              <div className="upload-field-image">
                  <label htmlFor="projectTitle" className="Subtitle text-gray">Project Thumbnail :<br /> <span className="Body text-gray">(optional)</span></label>
                  <ImageUploader onFileUpload={handleUploadThumbnail} onFileTooLarge={handleFileTooLarge} onInvalidFileType={handleInvalidFileType}/>
                  <p className="text-gray">
                  Project Thumbnail can be a static image which allows a judge to quickly identify your submitted project.
                  </p>
                </div>
                <div className="error-image">
                <label className="spacer"></label>
                {isFileTooLarge && (
                  <p className="Body">File size too large. Please upload a picture smaller than 2MB.</p>
                )}
                {isInvalidFileType && (
                  <p className="Body">Invalid file format. Please upload a picture in [ JPG, PNG ].</p>
                )}
                </div>
              <div className="upload-field">
                  <label htmlFor="uploadGraphic" className="Subtitle text-gray">Project Infographic :<br /> <span className="Body text-gray">(optional)</span></label>
                  <div className="input-container">
                    <FileUploader maxSize={5 * 1024 * 1024} 
                    removeFile={handleRemoveGraphic}
                    onFileUpload={handleUploadGraphic} 
                    infoGraphic={true}
                    errorMessage={errorGraphicMessage}
                    setErrorMessage={setErrorGraphicMessage}
                    accept=".jpg,.jpeg,.png" multiple={false} label="Upload jpg. or png." />
                  </div>
                  <p className="text-gray upload-tooltip">
                  Project infographic/ Poster can be a design guide image which provide overview information of the project.
                  </p>
                </div>
              <div className="upload-field">
                  <label htmlFor="uploadPitchDeck" className="Subtitle text-gray">Pitch Deck :<br /> <span className="Body text-gray">(optional)</span></label>
                  <FileUploader maxSize={10 * 1024 * 1024} 
                  removeFile={handleRemovePitchDeck}
                  onFileUpload={handleUploadPitchDeck} 
                  pitchDeck={true}
                  errorMessage={errorPitchDeckMessage}
                  setErrorMessage={setErrorPitchDeckMessage}
                  multiple={false} label="Upload only pdf." />
                  <p className="text-gray upload-tooltip">
                  Pitch Deck/Presentation file is a presentation designed specifically to propose your project idea, product, and solution.
                  </p>
                </div>
              <div className="upload-field">
                  <label htmlFor="uploadAdditional" className="Subtitle text-gray">Additional Files :<br /> <span className="Body text-gray">(optional)</span></label>
                  <FileUploader maxSize={10 * 1024 * 1024} 
                  onAdditionalRemove={handleRemoveAdditional}
                  errorMessage={errorAdditionalMessage}
                  setErrorMessage={setErrorAdditionalMessage}
                  onFileUpload={handleUploadAdditional} accept=".jpg,.jpeg,.png,.pdf" multiple={true} label="Upload jpg. png. pdf." />
                  <p className="text-gray upload-tooltip">Additional files can be any picture or pdf. file which your project want to upload for more clearly details.
                  </p>
                </div>
            </div>
        </div>
      </form>
        <div className="form-btn">
                <CancelBtn onCancel={handleCancel}/>
                <SubmitBtn disabled={isSubmitDisabled} onSubmit={handleSubmit}/>
        </div>
    </section>
        </div>
    </div>
    <Footer />
    <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm}/>
    </>
  );
}

export default SubmissionForm;