import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.scss';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';

document.title = 'ASEAN Digital Awards 2025';

const checkTokenValidity = () => {
  const token = localStorage.getItem('token');
  const tokenExpiry = localStorage.getItem('tokenExpiry');

  if (token && tokenExpiry) {
    const currentTime = new Date().getTime();
    if (currentTime > tokenExpiry) {
      // Token has expired
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiry');
      localStorage.removeItem('user');
    }
  } else {
    // No token found, clear any residual user data
    localStorage.removeItem('user');
  }
};

checkTokenValidity();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppRouter />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
