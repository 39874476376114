import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../images/logo.png';
import Footer from '../components/footer';
import LoginButton from '../components/modal/loginButton';
import '../styles/landingPage.scss';

function LandingPage() {
  document.title = 'Home | Judging.AseanDigitalAwards2025';
  const [phase, setPhase] = useState(null);
  useEffect(() => {
    const fetchStatusPhase = async () => {
      try {
        const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/phase');
        setPhase(response.data.submission);
      } catch (error) {
        console.error('Error fetching status phase', error);
      }
    };
    fetchStatusPhase();
  }, []);
  return (
    <>
    <div className="landing-page-container">
      <div className="landing">
        <div className="content">
          <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo"></img>
          <h1 className="H1 content-title">Welcome to Judging Portal <br />Asean Digital Awards 2025</h1>
          {phase !== null ? (
              <p className="Body content-desc">
                {phase ? 'Please upload your submission files for your country by logging into your account below.'
                       : 'Kindly access your account using the button below to review and score submitted projects.'}
              </p>
            ) : (
              <p className="Body content-desc">Loading...</p>
            )}
          <LoginButton />
        </div>
      </div>
      <Footer className="footer" />
      </div>
    </>
  );
}

export default LandingPage;
