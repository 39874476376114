import React, { useState } from 'react';
import '../styles/showAdditionalFile.scss';
import PDFicon from '../icons/filetypePdf.svg';
import JPGicon from '../icons/filetypeJpg.svg';
import PNGicon from '../icons/filetypePng.svg';
const ShowAdditionalFiles = ({ files }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const getFileName = (path) => {
    return path.split('/').pop();
  };

  const getFileIcon = (path) => {
    const extension = path.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <img src={PDFicon} alt="PDF Icon" />;
      case 'jpg':
      case 'jpeg':
        return <img src={JPGicon} alt="JPG Icon" />;
      case 'png':
        return <img src={PNGicon} alt="PNG Icon" />;
      default:
        return '📁'; // Default icon for other file types
    }
  };

  const handleFileClick = (file) => {
    const fileExtension = file.path.split('.').pop().toLowerCase();
    if (fileExtension === 'pdf') {
      window.open(file.url, '_blank');
    } else if (['png', 'jpg', 'jpeg'].includes(fileExtension)) {
      setSelectedImage(file.url);
    }
  };

  return (
    <div className="additional-files">
      {files && files.length > 0 ? (
        <div className="file-list">
          {files.map((file, index) => (
            <div
              key={index}
              className="file-item"
              onClick={() => handleFileClick(file)}
            >
              <span className="file-icon">{getFileIcon(file.path)}</span>
              <span className="file-name">{getFileName(file.path)}</span>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-files Body">No file</div>
      )}
      {selectedImage && (
        <div className="image-preview-overlay" onClick={() => setSelectedImage(null)}>
          <div className="image-preview-container">
            <img src={selectedImage} alt="Preview" className="image-preview" />
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowAdditionalFiles;