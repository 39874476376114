import React from 'react';
import { Modal } from 'antd';
import '../../styles/logoutModal.scss';
const LogoutModal = ({ open, onClose, onConfirm, admin }) => {
  const handleClose = () => {
    onClose();
  }
  const handleConfirm = () => {
    onConfirm();
  }
  return (
    <Modal className="center logout-modal" width={600} closeIcon={false} footer={null} open={open} onCancel={onClose}>
      <div className="logout-modal-content">
        <h1 className="H1">Are You Sure You <br /> Want to Log Out?</h1>
        <p className="Body">{ admin? 'Click Confirm to return to the homepage.' : 'Returning to the homepage will automatically log you out of  an account.  Are you sure you want to proceed?'}</p>
        <div className="button-section">
          <button onClick={handleClose} className="Body btn-cancel">Cancel</button>
          <button onClick={handleConfirm} className="Body btn-submit">Confirm</button>
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;