import React, { useState, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';

const CustomUploadButton = ({ label, accept, maxSize, onTotalSizeExceeded, multiple = false, onFileUpload, onAdditionalRemove, removeFile, editInitialFiles, pitchDeck = false, infoGraphic = false, errorMessage, setErrorMessage }) => {
  const [fileList, setFileList] = useState([]);
  
  const mimeTypes = {
    '.jpg': 'image/jpeg',
    '.jpeg': 'image/jpeg',
    '.png': 'image/png',
    '.pdf': 'application/pdf',
  };
  useEffect(() => {
    if (Array.isArray(editInitialFiles)) {
      const initialFiles = editInitialFiles.map((file, index) => {
        let fileName = 'Uploaded File';
        let fileUrl = '';
        let fileSize = 0;
        if (file.path && typeof file.path === 'string') {
          const pathParts = file.path.split('/');
          fileName = pathParts[pathParts.length - 1];
        }
        if (file.url && typeof file.url === 'string') {
          fileUrl = file.url;
        }
        if (file.size && typeof file.size === 'number') {
          fileSize = file.size;
        }
        return {
          uid: `${index}-${file.path}`,
          name: fileName,
          status: 'done',
          url: fileUrl,
          size: fileSize
        };
      });
      setFileList(initialFiles);
    } else if (editInitialFiles && typeof editInitialFiles === 'object') {
      let fileName = 'Uploaded File';
      let fileUrl = '';
      let fileSize = 0;
      if (editInitialFiles.path && typeof editInitialFiles.path === 'string') {
        const pathParts = editInitialFiles.path.split('/');
        fileName = pathParts[pathParts.length - 1];
      }
      if (editInitialFiles.url && typeof editInitialFiles.url === 'string') {
        fileUrl = editInitialFiles.url;
      }
      if (editInitialFiles.size && typeof editInitialFiles.size === 'number') {
        fileSize = editInitialFiles.size;
      }
      const initialFile = {
        uid: `0-${editInitialFiles.path}`,
        name: fileName,
        status: 'done',
        url: fileUrl,
        size: fileSize
      };
      setFileList([initialFile]);
    } else {
    }
  }, [editInitialFiles]);

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    if (pitchDeck) {
      onFileUpload(info);
      if (info.file.type !== 'application/pdf') {
        message.error('Please upload a PDF file.')
        console.log(info.file.type);
        return
      } 
    }
    // Ensure each file object includes the size property
    newFileList = newFileList.map(file => {
      if (!file.size && file.originFileObj) {
        file.size = file.originFileObj.size;
      }
      return file;
    });
  
    if (!multiple) {
      newFileList = newFileList.slice(-1);
    } else if (newFileList.length > 3) {
      setErrorMessage('You can only upload up to 3 files.');
      newFileList = newFileList.slice(0, 3);
    }
  
    const newTotalSize = newFileList.reduce((acc, file) => acc + file.size, 0);
  
    if (newTotalSize > maxSize) {
      setErrorMessage(`Total file size should not exceed ${maxSize / 1024 / 1024}MB`);
      if (onTotalSizeExceeded) {
        onTotalSizeExceeded(true);
      }
    } else {
      setErrorMessage('');
      if (onTotalSizeExceeded) {
        onTotalSizeExceeded(false);
      }
    }
    setFileList(newFileList);
    if (info.file.status !== 'uploading') {
      // console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      if (onFileUpload) {
        onFileUpload(info);
      }
    } else if (info.file.status === 'error') {
      setErrorMessage(`${info.file.name} file upload failed.`);
    }
  };
  
  const calculateTotalSize = (existingFiles, newFiles) => {
    if (!multiple) {
      const existingSize = existingFiles.reduce((acc, file) => acc + file.size, 0);
      return existingSize;
    } else {
      const existingSize = existingFiles.reduce((acc, file) => acc + (file.size || 0), 0);
      const newSize = newFiles.reduce((acc, file) => acc + (file.size || 0), 0);
      return existingSize + newSize;
    }
  };
  
  const beforeUpload = (file, fileList) => {
    const isAcceptedType = accept.split(',').some(ext => mimeTypes[ext.trim()] === file.type);
    if (!isAcceptedType) {
      setErrorMessage(`Invalid file format. Please upload a file in [ ${accept} ]`);
      return false;
    }
    if (fileList.length >= 3) {
      message.error('You can only upload up to 3 files.');
      return false;
    }
    const newTotalSize = calculateTotalSize(fileList, [file]);
    
    if (newTotalSize > maxSize) {
      setErrorMessage(`Total file size should not exceed ${maxSize / 1024 / 1024}MB`);
      return false;
    }
    setErrorMessage('');
    return true;
  };
  const onRemove = (file) => {
    const newFileList = fileList.filter(f => f.uid !== file.uid);
    const newTotalSize = newFileList.reduce((acc, file) => acc + (file.size || 0), 0);
    
    if (multiple) {
      if (newTotalSize <= maxSize) {
        setErrorMessage(false);
        if (onTotalSizeExceeded) {
          onTotalSizeExceeded(false);
        }
      } else {
        setErrorMessage(true);
        if (onTotalSizeExceeded) {
          onTotalSizeExceeded(true);
        }
      }
    } else {
      setErrorMessage(false);
    }
  
    setFileList(newFileList);
  
    if (onAdditionalRemove) {
      onAdditionalRemove(file);
    }
    if (removeFile) {
      removeFile(file);
    }
    return true;
  };

  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const props = {
    name: 'file',
    onChange: handleChange,
    beforeUpload: beforeUpload,
    onRemove: onRemove,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    accept: accept,
    multiple: multiple,
    fileList: fileList,
    customRequest: customRequest,
  };

  return (
    <>
    <div>
    <Upload {...props} className={errorMessage ? 'upload-error' : ''}>
      <Button icon={<DownloadOutlined />}>{label}</Button>
    </Upload>
    {errorMessage && (<span className="Body error-text">{
        infoGraphic ? 
        'File size too large. Please upload an Infographic smaller than [5] MB.' : 
        pitchDeck ? 
        'File size too large. Please upload a Pitch Deck smaller than [10] MB.' : 
        'Some files are too large. the total size of all files must not exceed [10] MB.'
        }
      </span>)}
    </div>
    </>
  );
};

export default CustomUploadButton;