import React, { useState } from 'react'
import '../styles/submissionOverview.scss';
import logo from '../images/logo.png';
import BN from '../images/flags/bn.svg';
import ID from '../images/flags/id.svg';
import KH from '../images/flags/kh.svg';
import LA from '../images/flags/la.svg';
import MM from '../images/flags/mm.svg';
import MY from '../images/flags/my.svg';
import PH from '../images/flags/ph.svg';
import SG from '../images/flags/sg.svg';
import TH from '../images/flags/th.svg';
import VN from '../images/flags/vn.svg';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../utils/authentication';
import Icon from '@mdi/react';
import { mdiTrayArrowDown } from '@mdi/js';
import SubmissionTable from '../components/submissionTable';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';

function SubmissionOverview() {
  const navigate = useNavigate();
  const goToUploadForm = () => {
    navigate('/overview/submission-form');
  }
  document.title = "Submission Overview | Judging.AseanDigitalAwards2025"
  useAuth();
  const userData = JSON.parse(localStorage.getItem('user'))
  const userCountry = userData.country
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const countryFlags = {
    BN: BN,
    ID: ID,
    KH: KH,
    LA: LA,
    MM: MM,
    MY: MY,
    PH: PH,
    SG: SG,
    TH: TH,
    VN: VN
  }
  const handleLogoutModalOpen = () => {
    setIsLogoutModalOpen(true);
  }
  const handleLogoutModalClose = () => {
    setIsLogoutModalOpen(false);
  }
  const handleLogoutConfirm = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  return (
    <>
      <div className="overview">
        <div className="content">
          <section className="top">
            <div className="top-logo">
              <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
            </div>
            <div className="top-breadcrumb">
              <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
              <span> / </span>
              <Link to="/overview" className="bread-overview">Submission Overview</Link>
            </div>
            <div className="top-title">
              <span className="H1">Submission Overview</span>
              <img src={countryFlags[userCountry]} width={32} height={32} alt={`${userCountry} flag`} />
            </div>
            <div className="top-desc">
              <p className="Body">
                To submit your country project for the ASEAN Digital Awards 2025 , please follow instructions below :
              </p>
              <div className="top-instruction">
                <p className="Subtitle">Instruction:</p>
                <ul className="Body">
                  <li>1) Click the "Click To Upload" button located on the top right to submit a new project.</li>
                  <li>2) Fill in the required details in the submission form.</li>
                  <li>3) Submit the form to confirm your submission.</li>
                  <li>4) After submission, the system will generate a unique Team ID.</li>
                  <li>5) Edit your submission anytime before the final deadline by selecting the edit icon on the right.</li>
                  <li>6) Ensure all entries are accurate before the event's closing date.</li>
                </ul>
              </div>
            </div>
            <div className="top-button">
              <button onClick={goToUploadForm} className="upload-btn">
                <Icon path={mdiTrayArrowDown} width={16} height={16} />
                <Link to="/overview/submission-form" className="Body">Upload Project</Link>
              </button>
            </div>
          </section>
          <section className="table-section">
            <SubmissionTable />
          </section>
        </div>
      </div>
      <Footer />
      <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
    </>
  )
}

export default SubmissionOverview;