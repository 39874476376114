import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const useAuth = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user')
    if (!token && user) {
      navigate('/');
    }
  }, [navigate]);
};

export default useAuth;