import React, { useState } from 'react';
import '../styles/summary.scss';
import Footer from '../components/footer';
import LogoutModal from '../components/modal/logoutModal';
import { Link, useParams } from 'react-router-dom';
import logo from '../images/logo.png';
const Summary = () => {
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
    const { title } = useParams();
    // const token = localStorage.getItem('token');
    const handleLogoutModalOpen = () => {
        setIsLogoutModalOpen(true);
    }
    const handleLogoutModalClose = () => {
        setIsLogoutModalOpen(false);
    }
    const handleLogoutConfirm = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    const formatTitle = (title) => {
        if (title === 'digital-start-up') {
            return 'Digital Start-up';
        } else {
            return title
                .split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
    };
    return (
        <>
            <div className="container-summary">
                <div className="summary">
                    <div className="content">
                        <section className="top">
                            <div className="top-logo">
                                <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
                            </div>
                            <div className="top-breadcrumb">
                                <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
                                <span> / </span>
                                <Link to="/award-categories" className="bread-award-categories">Award Categories</Link>
                                <span> / </span>
                                <Link to={`/award-categories/${title}`} className="bread-each-categories">{formatTitle(title)}</Link>
                                <span> / </span>
                                <Link to={`/award-categories/${title}/summary`} className="bread-summary">summary</Link>
                            </div>
                            <div className="top-title">
                                <span className="H1">{formatTitle(title)}</span>
                            </div>
                            <div className="top-desc">
                                <p className="Body">
                                    Summary of successfully submitted projects
                                </p>
                            </div>
                            <div className="top-btn">
                                <Link to={`/award-categories/${title}`}>
                                    <button className="Body">Back</button>
                                </Link>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer />
            </div>
            <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
        </>
    )
}

export default Summary;