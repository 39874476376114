import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import '../../styles/cancelFormButton.scss'
const SubmitFormBtn = ({ onCancel, isEdit, breadcrumb }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    onCancel();
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button className="btn-cancel" type="primary" onClick={showModal}>
      {breadcrumb ? 'Submission Overview' : 'Cancel'}
      </Button>
      <Modal className="submit-modal" closeIcon={false} footer={null} open={isModalOpen}>
        <div className="submit-modal-content">
            <h1 className="H1">{isEdit ? 'Cancel Your Changes Saved' : 'Cancel Your Submission'}</h1>
            <p className="Body">{isEdit ? 
            'Are you sure you want to cancel? All your Changes Saved  in the form will be lost.' : 
            'Are you sure you want to cancel? Your answers entered in the form will be lost, and your project submission will not be completed'}
            </p>
            <div className="button-section">
                <button className="btn-cancel" onClick={handleCancel}>Back</button>
                <button className="btn-submit" onClick={handleOk}>Cancel</button>
            </div>
        </div>
      </Modal>
    </>
  );
};
export default SubmitFormBtn;