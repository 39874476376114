import React, { useEffect, useState } from 'react';
import '../styles/submissionFormStyle.scss';
import axios from 'axios';
import logo from '../images/logo.png';
import BN from '../images/flags/bn.svg';
import ID from '../images/flags/id.svg';
import KH from '../images/flags/kh.svg';
import LA from '../images/flags/la.svg';
import MM from '../images/flags/mm.svg';
import MY from '../images/flags/my.svg';
import PH from '../images/flags/ph.svg';
import SG from '../images/flags/sg.svg';
import TH from '../images/flags/th.svg';
import VN from '../images/flags/vn.svg';
import tooltipIcon from '../icons/tooltip.svg'
import binIcon from '../icons/bin.svg'
import { Link, useParams } from 'react-router-dom';
import useAuth from '../utils/authentication';
import Footer from '../components/footer';
import ImageUploader from '../components/uploadImage';
import FileUploader from '../components/uploadFile';
import SubmitBtn from '../components/modal/submitFormButton'
import CancelBtn from '../components/modal/cancelFormButton'
import LogoutModal from '../components/modal/logoutModal';
import { isEnglishText, isValidYouTubeURL, isValidEmail } from '../utils/validation';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Tooltip, Button } from 'antd'
function EditSubmissionForm() {
  document.title = "Submission form | Judging.AseanDigitalAwards2025"
  const navigate = useNavigate();
  useAuth();
  const userData = JSON.parse(localStorage.getItem('user'))
  const userCountry = userData.country
  const token = localStorage.getItem('token')
  const { id } = useParams();

  const countryFlags = {
    BN: BN,
    ID: ID,
    KH: KH,
    LA: LA,
    MM: MM,
    MY: MY,
    PH: PH,
    SG: SG,
    TH: TH,
    VN: VN
  }
  const countryNames = {
    BN: 'Brunei',
    ID: 'Indonesia',
    KH: 'Cambodia',
    LA: 'Laos',
    MM: 'Myanmar',
    MY: 'Malaysia',
    PH: 'Philippines',
    SG: 'Singapore',
    TH: 'Thailand',
    VN: 'Vietnam',
  };
  const [categories, setCategories] = useState([
    { value: 1, label: 'Public Sector' },
    { value: 2, label: 'Private Sector' },
    { value: 3, label: 'Digital Inclusivity' },
    { value: 4, label: 'Digital Content' },
    { value: 5, label: 'Digital Start-up' },
    { value: 6, label: 'Digital Innovation' }
  ]);
  useEffect(() => {
    const fetchCategoryCount = async () => {
      try {
        const response = await axios.get('https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/category-count', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const categoryCounts = response.data;
        setCategories(prevCategories => {
          return prevCategories.map(category => {
            const categoryCount = categoryCounts.find(count => count.category === category.value);
            if (categoryCount && (categoryCount.count === 3 || categoryCount.is_full)) {
              return { ...category, label: `${category.label} [Full]`, disabled: true };
            }
            return category;
          });
        });
      } catch (error) {
        console.error('Error fetching category count:', error);
      }
    };
    fetchCategoryCount();
  }, [token]);
  const { TextArea } = Input;
  const countryInput = countryNames[userCountry];
  const [category, setCategory] = useState(Number);
  const [teamName, setTeamName] = useState('');
  const [teamMembers, setTeamMembers] = useState(['', '']);
  const [contactEmail, setContactEmail] = useState('');
  const [projectTitle, setProjectTitle] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  const [projectGoal, setProjectGoal] = useState('');
  const [projectProblem, setProjectProblem] = useState('');
  const [projectKey, setProjectKey] = useState('');
  const [projectInnovation, setProjectInnovation] = useState('');
  const [projectURL, setProjectURL] = useState('');
  const [thumbnailFile, setThumbnailFile] = useState(null)
  const [infographicFile, setInfographicFile] = useState('');
  const [pitchDeckFile, setPitchDeckFile] = useState(null);
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [isFileTooLarge, setIsFileTooLarge] = useState(false);
  const [isInvalidFileType, setIsInvalidFileType] = useState(false);
  const [errorGraphicMessage, setErrorGraphicMessage] = useState(false);
  const [errorPitchDeckMessage, setErrorPitchDeckMessage] = useState(false);
  const [errorAdditionalMessage, setErrorAdditionalMessage] = useState(false);
  const [errorTeamName, setErrorTeamName] = useState(false);
  const [errorTeamMembers, setErrorTeamMembers] = useState([false]);
  const [errorContactEmail, setErrorContactEmail] = useState(false);
  const [errorProjectTitle, setErrorProjectTitle] = useState(false);
  const [errorProjectDesc, setErrorProjectDesc] = useState(false);
  const [errorProjectGoal, setErrorProjectGoal] = useState(false);
  const [errorProjectProblem, setErrorProjectProblem] = useState(false);
  const [errorProjectKey, setErrorProjectKey] = useState(false);
  const [errorProjectInnovation, setErrorProjectInnovation] = useState(false);
  const [errorProjectURL, setErrorProjectURL] = useState(false);
  const [categoryTouched, setCategoryTouched] = useState(false);
  const [teamNameTouched, setTeamNameTouched] = useState(false);
  const [teamMemberTouched, setTeamMemberTouched] = useState([false]);
  const [contactEmailTouched, setContactEmailTouched] = useState(false);
  const [projectTitleTouched, setProjectTitleTouched] = useState(false);
  const [projectDescTouched, setProjectDescTouched] = useState(false);
  const [projectURLTouched, setProjectURLTouched] = useState(false)
  const handleCategoryChange = (value) => {
    setCategory(value);
    setCategoryTouched(true);
  };

  const handleInputChange = (setter, errorSetter, validator, optional = false) => (e) => {
    const value = e.target.value;
    setter(value);
    if (!value && !optional) {
      errorSetter(true);
    } else if (validator ? !validator(value) : !isEnglishText(value)) {
      errorSetter(true);
    } else {
      errorSetter(false);
    }
  };
  const handleTeamNameChange = (e) => {
    handleInputChange(setTeamName, setErrorTeamName, isEnglishText)(e)
    setTeamNameTouched(true)
  }
  const handleProjectTitleChange = (e) => {
    handleInputChange(setProjectTitle, setErrorProjectTitle, isEnglishText)(e)
    setProjectTitleTouched(true)
  }
  const handleProjectDescChange = (e) => {
    handleInputChange(setProjectDesc, setErrorProjectDesc, isEnglishText)(e)
    setProjectDescTouched(true)
  }
  const handleProjectURLChange = (e) => {
    handleInputChange(setProjectURL, setErrorProjectURL, isValidYouTubeURL)(e)
    setProjectURLTouched(true)
  }
  const handleContactEmailChange = (e) => {
    handleInputChange(setContactEmail, setErrorContactEmail, isValidEmail)(e)
    setContactEmailTouched(true)
  }
  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await fetch(`https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/${id}/detail`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // console.log(data);
        setCategory(data.category);
        setTeamName(data.team_name);
        setTeamMembers(data.team_members.map(member => member.name));
        setContactEmail(data.contact_email);
        setProjectTitle(data.title);
        setProjectDesc(data.description);
        setProjectGoal(data.propose_and_goals);
        setProjectProblem(data.problem_and_solution);
        setProjectKey(data.key_features);
        setProjectInnovation(data.innovation);
        setProjectURL(data.video_url);
        setThumbnailFile(data.thumbnail ? data.thumbnail : null);
        setInfographicFile(data.infographic ? data.infographic : '');
        setPitchDeckFile(data.pitch_deck ? data.pitch_deck : null);
        setAdditionalFiles(data.additional_files);
      } catch (error) {
        console.error('Error fetching project details:', error);
      }
    }
    fetchProjectDetails();
  }, [id, token])

  const handleFileTooLarge = (isTooLarge) => {
    setIsFileTooLarge(isTooLarge);
  };
  const handleInvalidFileType = (isInvalid) => {
    setIsInvalidFileType(isInvalid);
  };
  const handleAddMember = () => {
    if (teamMembers.length < 10) {
      setTeamMembers([...teamMembers, '']);
    }
  };

  const handleRemoveMember = (index) => {
    if (index <= 1) {
      return;
    }
    const newTeamMembers = teamMembers.filter((_, i) => i !== index);
    setTeamMembers(newTeamMembers);
    const newErrorTeamMembers = errorTeamMembers.filter((_, i) => i !== index);
    setErrorTeamMembers(newErrorTeamMembers);
    const newTeamMembersTouched = teamMemberTouched.filter((_, i) => i !== index);
    setTeamMemberTouched(newTeamMembersTouched);
  };

  const handleMemberChange = (index, value) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers[index] = value;
    setTeamMembers(newTeamMembers);
    const newErrorTeamMembers = [...errorTeamMembers];
    newErrorTeamMembers[index] = !isEnglishText(value);
    setErrorTeamMembers(newErrorTeamMembers);
    const newTeamMembersTouched = [...teamMemberTouched];
    newTeamMembersTouched[index] = true;
    setTeamMemberTouched(newTeamMembersTouched);
  };
  const handleUploadThumbnail = async (file) => {
    // console.log('thumb file: ', file);

    const formData = new FormData();
    formData.append('type', 'thumbnail')
    formData.append('file', file);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      // console.log('response:', response.data);
      const thumbnailPath = response.data.path
      // console.log(thumbnailPath);
      setThumbnailFile(thumbnailPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  }
  const handleUploadGraphic = async (file) => {
    // console.log('graphic file: ', file);
    const graphicFile = file.file.originFileObj;
    const formData = new FormData();
    formData.append('type', 'infographic')
    formData.append('file', graphicFile);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      // console.log('response:', response.data);
      const infoGraphicPath = response.data.path
      // console.log(infoGraphicPath);
      setInfographicFile(infoGraphicPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleUploadPitchDeck = async (file) => {
    // console.log('pitchDeck file: ', file.file);
    const pitchDeckFile = file.file
    
    const formData = new FormData();
    formData.append('type', 'pitch_deck')
    formData.append('file', pitchDeckFile);
    try {
      const response = await axios.post(
        'https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      // console.log('response:', response.data);
      const pitchDeckPath = response.data.path
      // console.log(pitchDeckPath);
      setPitchDeckFile(pitchDeckPath);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleUploadAdditional = async (file) => {
    // console.log('Additional file: ', file);
    const additionalFile = file.file.originFileObj
    const formData = new FormData();
    formData.append('type', 'additional')
    formData.append('file', additionalFile)
    try {
      const response = await axios.post(
        `https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/upload`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      //console.log('response:', response.data);
      const additionalPath = response.data.path
      setAdditionalFiles((prevFiles) => [...prevFiles, { path: additionalPath, new: true }]);
    } catch (error) {
      console.error('Error: ', error);
    }
  };
  const handleRemoveAdditional = (file) => {
    //console.log('remove additional: ', file);
    //console.log('additionalFiles: ', additionalFiles);
    setAdditionalFiles((prevFiles) => prevFiles.filter((f) => {
      if (f.new) {
        const filePath = file.uid.split('-').slice(1).join('-');
        return f.path !== filePath;
      } else {
        const fileName = f.path.split('/').pop();
        return fileName !== file.name;
      }
    }));
  };
  useEffect(() => {
    //console.log('Updated additionalFiles: ', additionalFiles);
  }, [additionalFiles]);
  const handleRemoveGraphic = (file) => {
    //console.log('remove graphic: ', file);
    setInfographicFile('');
  }
  const handleRemovePitchDeck = (file) => {
    //console.log('remove pitchDeck: ', file);
    setPitchDeckFile(null);
  }
  const uniqueFilesMap = new Map();
  const handleSaveChanges = async (e) => {
    // e.preventDefault()
    //console.log('additionalFiles: ', additionalFiles);
    additionalFiles.forEach(file => {
      if (typeof file === 'string') {
        const fileName = file.split('/').pop();
        if (!uniqueFilesMap.has(fileName)) {
          uniqueFilesMap.set(fileName, { path: file });
        }
      } else if (file && typeof file === 'object' && file.path) {
        const fileName = file.path.split('/').pop();
        if (!uniqueFilesMap.has(fileName)) {
          uniqueFilesMap.set(fileName, { path: file.path });
        }
      } else {
        console.error('File is not a valid string or object with a path:', file);
      }
    });

    const uniqueFiles = Array.from(uniqueFilesMap.values());
    setAdditionalFiles(uniqueFiles);
    const formData = {
      category,
      team_name: teamName,
      team_members: teamMembers.map((member) => ({ name: member })),
      contact_email: contactEmail,
      title: projectTitle,
      description: projectDesc,
      propose_and_goals: projectGoal,
      problem_and_solution: projectProblem,
      key_features: projectKey,
      innovation: projectInnovation,
      video_url: projectURL,
      thumbnail: thumbnailFile?.path || thumbnailFile,
      infographic: infographicFile?.path || infographicFile,
      pitch_deck: pitchDeckFile?.path || pitchDeckFile,
      additional_files: uniqueFiles?.path || uniqueFiles
    }
    //console.log(formData);
    try {
      await axios.put(
        `https://asean-be-prod-1090666193530.asia-southeast1.run.app/api/projects/${id}/detail`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      //console.log('Response: ', response.data);
      setIsEditSuccess(true);
    } catch (error) {
      console.error('Error: ', error);
    }
  }
  const handleCancel = (e) => {
    navigate('/overview')
  }
  const handleSecondModalClose = () => {
    setIsEditSuccess(false);
  };
  const handleLogoutModalOpen = () => {
    setIsLogoutModalOpen(true);
  }
  const handleLogoutModalClose = () => {
    setIsLogoutModalOpen(false);
  }
  const handleLogoutConfirm = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    window.location.href = '/';
  }
  const isSubmitDisabled =
    isFileTooLarge ||
    errorGraphicMessage ||
    errorPitchDeckMessage ||
    errorAdditionalMessage ||
    errorTeamName ||
    errorTeamMembers.includes(true) ||
    errorContactEmail ||
    errorProjectTitle ||
    errorProjectDesc ||
    errorProjectGoal ||
    errorProjectProblem ||
    errorProjectKey ||
    errorProjectInnovation ||
    errorProjectURL ||
    !category ||
    !teamName ||
    teamMembers.some(member => !member) ||
    !projectTitle ||
    !projectDesc;
  return (
    <>
      <div className="projectsubmit">
        <div className="content">
          <section className="top">
            <div className="top-logo">
              <img src={logo} width={183} height={88} alt="ASEAN Digital Award logo" />
            </div>
            <div className="top-breadcrumb">
              <span onClick={handleLogoutModalOpen} className="bread-home">Home</span>
              <span> / </span>
              <CancelBtn class="overview" breadcrumb={true} isEdit={true} onCancel={handleCancel} />
              <span> / </span>
              <Link to="/overview/submission-form" className="bread-submissionform">Edit Submission Form</Link>
            </div>
            <div className="top-title">
              <span className="H1">Edit Your Project Submission</span>
              <img src={countryFlags[userCountry]} width={32} height={32} alt={`${userCountry} flag`} />
            </div>
            <div className="top-desc">
              <p className="Body">
                Update your project details for the ASEAN Digital Awards 2025.
                Please ensure your answers are accurate and complies with the submission guidelines.
                Changes can be made until the final deadline.
              </p>
            </div>
          </section>
          <section className="form-section">
            <form className="project-form">
              <div className="identity-infomation">
                <h1 className="Title-1">1. Identity Information <span className="text-red">(Required)</span></h1>
                <div className="info-identity">
                  <div className="identity-field">
                    <label htmlFor="country" className="Subtitle">Country :</label>
                    <Input value={countryInput} id="country" placeholder="country" readOnly />
                    <Tooltip placement="topRight" title={countryInput}>
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="identity-field">
                    <label htmlFor="category" className="Subtitle">Select Category* :</label>
                    <div className="input-container">
                      <Select id="category" placeholder="Choose the  relevant category"
                        className={!category && categoryTouched ? 'error' : ''}
                        value={category}
                        onChange={handleCategoryChange}
                        options={categories}
                      />
                      {!category && categoryTouched && <span className="error-text">Input required.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Choose your category that best represents your 
                                                 project’s sector for depa ASEAN Digital Awards.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="identity-field">
                    <label htmlFor="teamName" className="Subtitle">Team Name* :</label>
                    <div className="Body input-container">
                      <Input required id="teamName" placeholder="Enter the team name" value={teamName}
                        className={(!teamName && teamNameTouched) || errorTeamName ? 'errorInput' : ''} maxLength={280}
                        onChange={handleTeamNameChange}
                      />
                      {(!teamName && teamNameTouched && <span className="error-text">Input required.</span>) ||
                        (errorTeamName && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                    </div>
                    <Tooltip placement="topRight" title="Enter your official name of your team or 
                                                 organization participating in this competition.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="identity-field-member">
                    <label htmlFor="teamMembers" className="Subtitle">Team Members* :</label>
                    <div className="team-member-field">
                      {teamMembers.map((member, index) => (
                        <div key={index} className="team-member-input input-container">
                          <Input required value={member} onChange={(e) => handleMemberChange(index, e.target.value)}
                            placeholder="Enter first name and last name of team member"
                            className={(!member && teamMemberTouched[index]) || errorTeamMembers[index] ? 'errorInput' : ''}
                            suffix={<img src={binIcon} alt="Remove" width={16} height={16} maxLength={280}
                              onClick={() => handleRemoveMember(index)} style={{ cursor: 'pointer' }}
                            />
                            }
                          />
                          {(!member && teamMemberTouched[index] && <span className="error-text">Input required.</span>) ||
                            (errorTeamMembers[index] && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                        </div>
                      ))}
                    </div>
                    <Tooltip placement="topRight" title="Click ‘Add’ your team member's first and last name into the filling blank
                                                       by entering without a courtesy title such as ‘Mr.’ ‘Mrs.’ or ‘Miss’.">
                      <img className="tooltips-member" src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="add-btn">
                    <label className="spacer"></label>
                    {teamMembers.length < 10 && (
                      <Button onClick={handleAddMember} type="default">+ Add</Button>
                    )}
                  </div>
                  <div className="identity-field">
                    <label htmlFor="contactEmail" className="Subtitle">Contact Email* :</label>
                    <div className="input-container">
                      <Input id="contactEmail" placeholder="Enter your email address" value={contactEmail}
                        className={errorContactEmail ? 'errorInput' : ''} maxLength={280}
                        onChange={handleContactEmailChange}
                      />
                      {(!contactEmail && contactEmailTouched && <span className="error-text">Input required.</span>) ||
                        (errorContactEmail && <span className="error-text">Please enter a valid email address.</span>)}
                    </div>
                    <Tooltip placement="topRight" title="Provide a contact email address for any
                                                 correspondence related to your submission.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="project-infomation">
                <h1 className="Title-1">2. Team Infomation <span className="text-gray">(This information part labelled * is required to explain project detail of your country.)</span></h1>
                <div className="info-project">
                  <div className="project-field">
                    <label htmlFor="projectTitle" className="Subtitle">Project Title* :<br /> <span className="Body text-red">(Required)</span></label>
                    <div className="input-container">
                      <Input required id="projectTitle" placeholder="Enter the official title of your project"
                        className={!projectTitle || errorProjectTitle ? 'errorInput' : ''}
                        onChange={handleProjectTitleChange}
                        value={projectTitle}
                      />
                      {(!projectTitle && projectTitleTouched && <span className="error-text">Input required.</span>) ||
                        (errorProjectTitle && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                    </div>
                    <Tooltip placement="topRight" title="Enter a clear and concise title for your project. 
                                                     This will be displayed during the evaluation process.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field-desc">
                    <label htmlFor="projectDesc" className="Subtitle">Project Description* :<br /> <span className="Body text-red">(Required)</span></label>
                    <div className={(!projectDesc && projectDescTouched) || errorProjectDesc ? 'input-container input-container-error' : "input-container"}>
                      <TextArea required className="desc-area"
                        id={(!projectDesc && projectDescTouched) || errorProjectDesc ? 'errorInput' : 'projectDesc'}
                        placeholder="Provide a brief overview of your project"
                        showCount
                        maxLength={1000}
                        onChange={handleProjectDescChange}
                        value={projectDesc}
                      />
                      {(!projectDesc && <span className="error-text">Input required.</span>) ||
                        (errorProjectDesc && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>)}
                    </div>
                    <Tooltip placement="topRight" title="Provide notably overview of your project. Focus on ‘what’ 
                                                     and ‘why’ of the project, and avoid not required detail.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field-url">
                    <label htmlFor="uploadVideo" className="Subtitle text-gray">Project Video URL* :<br /> <span className="Body text-red">(Required)</span></label>
                    <div className="input-container">
                      <Input id="uploadVideo" placeholder="Enter the link to your project video"
                        className={errorProjectURL ? 'errorInput' : ''} maxLength={280}
                        onChange={handleProjectURLChange}
                        value={projectURL}
                      />
                      <span className="text-gray mt-[8px] pl-[4px]">The video link you can insert can be Youtube link, Google  link, or any video url.</span>
                      {!projectURL && projectURLTouched && <span className="error-text">Input required.</span>}
                      {errorProjectURL && projectURL && <span className="error-text">Invalid URL format. Please enter a valid YouTube URL, Google link, or any video url.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Provide a YouTube link or streaming video link to showcase your project. 
                                                       Ensure that link is accessible and set to public.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field">
                    <label htmlFor="projectGoal" className="Subtitle text-gray">Purpose and Goals :<br /> <span className="Body text-gray">(optional)</span></label>
                    <div className={errorProjectGoal ? 'input-container input-container-error' : "input-container"}>
                      <TextArea required
                        id={errorProjectGoal ? 'errorInput' : 'projectGoal'}
                        placeholder="(Optional) Provide a brief overview of your project"
                        showCount
                        maxLength={1000}
                        onChange={handleInputChange(setProjectGoal, setErrorProjectGoal, undefined, true)}
                        value={projectGoal}
                      />
                      {errorProjectGoal && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Describe project's purpose and objectives . 
                                                     What does it aim to achieve, and why is it important?">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field">
                    <label htmlFor="projectProblem" className="Subtitle text-gray">Problem and Solution :<br /> <span className="Body text-gray">(optional)</span></label>
                    <div className={errorProjectProblem ? 'input-container input-container-error' : "input-container"}>
                      <TextArea
                        id={errorProjectProblem ? 'errorInput' : 'projectProblem'}
                        placeholder="(Optional) Provide a brief overview of your project"
                        showCount
                        maxLength={1000}
                        onChange={handleInputChange(setProjectProblem, setErrorProjectProblem, undefined, true)}
                        value={projectProblem}
                      />
                      {errorProjectProblem && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Outline the specific problem your project addresses and provide 
                                                     offered solution. Highlight project's unique approach and benefits.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field">
                    <label htmlFor="projectKey" className="Subtitle text-gray">Key Features and Impact :<br /> <span className="Body text-gray">(optional)</span></label>
                    <div className={errorProjectKey ? 'input-container input-container-error' : "input-container"}>
                      <TextArea
                        id={errorProjectKey ? 'errorInput' : 'projectKey'}
                        placeholder="(Optional) Provide a brief overview of your project"
                        showCount
                        maxLength={1000}
                        onChange={handleInputChange(setProjectKey, setErrorProjectKey, undefined, true)}
                        value={projectKey}
                      />
                      {errorProjectKey && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Explain dominant key features and its potential impact. 
                                                     Focus on what sets it apart from others.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                  <div className="project-area-field">
                    <label htmlFor="projectInnovation" className="Subtitle text-gray">Innovation and Methodology :<br /> <span className="Body text-gray">(optional)</span></label>
                    <div className={errorProjectInnovation ? 'input-container input-container-error' : "input-container"}>
                      <TextArea
                        id={errorProjectInnovation ? 'errorInput' : 'projectInnovation'}
                        placeholder="(Optional) Provide a brief overview of your project"
                        showCount
                        maxLength={1000}
                        onChange={handleInputChange(setProjectInnovation, setErrorProjectInnovation, undefined, true)}
                        value={projectInnovation}
                      />
                      {errorProjectInnovation && <span className="error-text">Your input contains unsupported characters. Use English letters only.</span>}
                    </div>
                    <Tooltip placement="topRight" title="Describe any innovative approaches or methodologies used in your project.
                                                     This field is optional but encouraged for more context.">
                      <img src={tooltipIcon} width={16} height={16} alt="tooltips" />
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="upload-infomation">
              <h1 className="Title-1">3. Upload file <span className="Title-1 text-gray">(This is an optional part for uploading project files)</span></h1>
                <div className="info-upload">
                  <div className="upload-field-image">
                    <label htmlFor="projectThumbnail" className="Subtitle text-gray">Project Thumbnail :<br /> <span className="Body text-gray">(optional)</span></label>
                    <ImageUploader editPreviewURL={thumbnailFile} onFileUpload={handleUploadThumbnail} onFileTooLarge={handleFileTooLarge} onInvalidFileType={handleInvalidFileType} />
                    <p className="text-gray">
                      Project Thumbnail can be a static image which allows a judge to quickly identify your submitted project.
                    </p>
                  </div>
                  <div className="error-image">
                    <label className="spacer"></label>
                    {isFileTooLarge && (
                      <p className="Body">File size too large. Please upload a picture smaller than 2MB.</p>
                    )}
                    {isInvalidFileType && (
                      <p className="Body">Invalid file format. Please upload a picture in [ JPG, PNG ].</p>
                    )}
                  </div>
                  <div className="upload-field">
                    <label htmlFor="uploadGraphic" className="Subtitle text-gray">Project Infographic :<br /> <span className="Body text-gray">(optional)</span></label>
                    <div className="input-container">
                      <FileUploader maxSize={5 * 1024 * 1024}
                        removeFile={handleRemoveGraphic}
                        onFileUpload={handleUploadGraphic}
                        infoGraphic={true}
                        errorMessage={errorGraphicMessage}
                        setErrorMessage={setErrorGraphicMessage}
                        editInitialFiles={infographicFile}
                        accept=".jpg,.jpeg,.png" multiple={false} label="Upload jpg. or png." />
                    </div>
                    <p className="text-gray">
                      Project infographic/ Poster can be a design guide image which provide overview information of the project.
                    </p>
                  </div>
                  <div className="upload-field">
                    <label htmlFor="uploadPitchDeck" className="Subtitle text-gray">Pitch Deck :<br /> <span className="Body text-gray">(optional)</span></label>
                    <FileUploader editInitialFiles={pitchDeckFile} maxSize={10 * 1024 * 1024}
                      removeFile={handleRemovePitchDeck}
                      pitchDeck={true}
                      errorMessage={errorPitchDeckMessage}
                      setErrorMessage={setErrorPitchDeckMessage}
                      onFileUpload={handleUploadPitchDeck} multiple={false} label="Upload only pdf." />
                    <p className="text-gray">
                      Pitch Deck/Presentation file is a presentation designed specifically to propose your project idea, product, and solution.
                    </p>
                  </div>
                  <div className="upload-field">
                    <label htmlFor="uploadAdditional" className="Subtitle text-gray">Additional Files :<br /> <span className="Body text-gray">(optional)</span></label>
                    <FileUploader editInitialFiles={additionalFiles} maxSize={10 * 1024 * 1024}
                      onAdditionalRemove={handleRemoveAdditional}
                      onFileUpload={handleUploadAdditional}
                      errorMessage={errorAdditionalMessage}
                      setErrorMessage={setErrorAdditionalMessage}
                      accept=".jpg,.jpeg,.png,.pdf" multiple={true} label="Upload jpg. png. pdf." />
                    <p className="text-gray">Additional files can be any picture or pdf. file which your project want to upload for more clearly details.
                    </p>
                  </div>
                </div>
              </div>
            </form>
            <div className="form-btn">
              <CancelBtn isEdit={true} onCancel={handleCancel} />
              <SubmitBtn isEdit={true} disabled={isSubmitDisabled} isEditSuccess={isEditSuccess} onSubmit={handleSaveChanges} onSecondModalClose={handleSecondModalClose} />
            </div>
          </section>
        </div>
      </div>
      <Footer />
      <LogoutModal open={isLogoutModalOpen} onClose={handleLogoutModalClose} onConfirm={handleLogoutConfirm} />
    </>
  );
}

export default EditSubmissionForm;